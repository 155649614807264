<template>
	<div>
		<ecommerce-statistics :data="data" :title="'Estadísticas'" :caption="today | myGlobalDayWhour" />
	</div>
</template>

<script>
import moment from "moment";
export default {
	created() {},
	mounted() {
		this.getDashboardData();
		if (this.isEncastador) {
			this.$router.push({ name: "administrativo-gastos-sr" });
		}
	},
	data() {
		return {
			data: [
				{
					color: "light-primary",
					customClass: "mb-2 mb-xl-0",
					icon: "BarChart2Icon",
					subtitle: "Balance inicial",
					title: 0,
				},
				{
					color: "light-danger",
					customClass: "mb-2 mb-xl-0",
					icon: "TrendingDownIcon",
					subtitle: "Gastos",
					title: "230k",
				},
				{
					color: "light-success",
					customClass: "mb-2 mb-xl-0",
					icon: "TrendingUpIcon",
					subtitle: "Depósitos",
					title: "230k",
				},
				{
					color: "light-info",
					customClass: "mb-2 mb-xl-0",
					icon: "TargetIcon",
					subtitle: "Balance",
					title: "230k",
				},
			],
			dashboardData: [],
			today: moment().format("YYYY-MM-DD"),
		};
	},
	computed: {
		newBalance: {
			get() {
				return Number(this.deposits) - Number(this.expenses);
			},
			set(val) {
				return val;
			},
		},
		deposits: {
			get() {
				if (this.dashboardData[1]) {
					return this.dashboardData[1].totals;
				} else {
					return 0;
				}
			},
			set(val) {
				return val;
			},
		},
		expenses: {
			get() {
				if (this.dashboardData[0]) {
					return this.dashboardData[0].totals;
				} else {
					return 0;
				}
			},
			set(val) {
				return val;
			},
		},
	},
	methods: {
		getDashboardData() {
			axios.get("/api/administrative/get-dashboard-data").then((res) => {
				this.dashboardData = res.data;
				this.data[0].title = "S/ 0.00";
				this.data[1].title = "S/ " + this.expenses;
				this.data[2].title = "S/ " + this.deposits;
				this.data[3].title = "S/ " + this.newBalance;
			});
		},
	},
};
</script>

<style></style>
